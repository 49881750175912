import { Injectable } from '@angular/core';
import {Router} from "@angular/router";
import {environment} from "../../environments/environment";
import {guardSessionWithMeta, SessionWithMeta} from "../apimodels/session";

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {
  private session: SessionWithMeta|null|undefined = undefined;

  constructor(private router: Router) {
    //
  }

  setAuth(session: SessionWithMeta): void {
    localStorage.setItem(environment.sessionkey, JSON.stringify(session));
    this.session = undefined;
  }

  public clearAuth() {
    localStorage.removeItem(environment.sessionkey);
    this.session = undefined;
    this.router.navigate(['/login']).then(()=>{});
  }

  public isAuthenticated(): boolean {
    return this.getSession() !== null;
  }

  public getSession(): SessionWithMeta|null {
    if (this.session !== undefined) return this.session;

    const blob = localStorage.getItem(environment.sessionkey);
    if (blob == null) return this.session = null;

    try {

      const sess = JSON.parse(blob) as SessionWithMeta;

      if (!guardSessionWithMeta(sess)) return this.session = null;

      return this.session = sess;
    } catch (e) {
      return this.session = null;
    }
  }

  getSecret() {
    return this.getSession()?.secret ?? 'NONE';
  }
}
