<div *ngIf="loading" class="loading-root">
  <fa-icon icon="spinner" [spin]="true"></fa-icon>
</div>

<div *ngIf="!loading" class="root">

  <div class="entrylist">

    <ng-container *ngFor="let entity of entities">

      <app-button-episode *ngIf="entity.type === 'EPISODE'" [episode]="entity.episode!" [singleImage]="false" (click)="onClickEpisode(entity.episode!)"></app-button-episode>

      <app-button-podcast *ngIf="entity.type === 'PODCAST'" [podcast]="entity.podcast!"                       (click)="onClickPodcast(entity.podcast!)"></app-button-podcast>

    </ng-container>
  </div>

</div>
