
<div id="player" *ngIf="activeEpisode !== null" >


  <img class="cover" [src]="activeEpisode.coverImage | imageurl" alt="Cover">

  <span class="podcast-title">{{activeEpisode.podcast.title}}</span>
  <span class="episode-title">{{activeEpisode.title}}</span>

  <fa-icon *ngIf="!isPlaying" class="control play" icon="play"  (click)="play()"  ></fa-icon>
  <fa-icon *ngIf="isPlaying"  class="control play" icon="pause" (click)="pause()" ></fa-icon>

  <div class="progress">
    <div class="bar" [ngStyle]="{'width': progressPerc + '%'}"></div>
  </div>

</div>
