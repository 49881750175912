import { Injectable } from '@angular/core';
import {APIMethod, APIRequestMethod, APIServiceRef} from "../utils/apiMethod";
import {HttpClient} from "@angular/common/http";
import {AuthenticationService} from "./authentication.service";
import {SessionWithMeta} from "../apimodels/session";
import {PodcastWithMeta} from "../apimodels/podcast";
import {EpisodeWithMeta} from "../apimodels/episode";
import {SearchResult} from "../apimodels/search";
import {Router} from "@angular/router";
import {HotToastService} from "@ngneat/hot-toast";

@Injectable({ providedIn: 'root' })
export class APIService {

  private static ENDPOINTS = {

    getSession:          new APIMethod<SessionWithMeta>                                                   (APIRequestMethod.GET,    'session'                   ),
    createSession:       new APIMethod<SessionWithMeta>                                                   (APIRequestMethod.POST,   'session'                   ),
    deleteSession:       new APIMethod<{}>                                                                (APIRequestMethod.DELETE, 'session'                   ),
    listPodcasts:        new APIMethod<{nextPageToken:string,pageSize:number,podcasts:PodcastWithMeta[]}> (APIRequestMethod.GET,    'podcasts'                  ),
    listEpisodes:        new APIMethod<{nextPageToken:string,pageSize:number,episodes:EpisodeWithMeta[]}> (APIRequestMethod.GET,    'episodes'                  ),
    getPodcast:          new APIMethod<PodcastWithMeta>                                                   (APIRequestMethod.GET,    'podcasts/{podid}'          ),
    getPodcastEpisodes:  new APIMethod<{nextPageToken:string,pageSize:number,episodes:EpisodeWithMeta[]}> (APIRequestMethod.GET,    'podcasts/{podid}/episodes' ),
    search:              new APIMethod<{nextPageToken:string,pageSize:number,entities:SearchResult[]}>    (APIRequestMethod.POST,   'search'                    ),
    register:            new APIMethod<SessionWithMeta>                                                   (APIRequestMethod.POST,   'users'                     ),
  };

  private readonly ref: APIServiceRef;

  constructor(private http: HttpClient,
              private auth: AuthenticationService,
              private toast: HotToastService,
              private router: Router) {
    this.ref = ({http: http, auth: auth, router: router, toast: toast, api: this});
  }

  getSession(ident: string, password: string) {
    return APIService.ENDPOINTS.getSession.run(this.ref, {}, {'identifier': ident, 'password': password});
  }

  createSession(deviceName: string, ephemeral: boolean, username: string, password: string, totp: string) {
    return APIService.ENDPOINTS.createSession.run(this.ref, {}, {'deviceName': deviceName, 'ephemeral': ephemeral, 'password': password, 'totp': totp, 'username': username});
  }

  register(deviceName: string, mail: string, username: string, password: string) {
    return APIService.ENDPOINTS.register.run(this.ref, {}, {deviceName, mail, username, password});
  }

  deleteSession() {
    return APIService.ENDPOINTS.deleteSession.run(this.ref, {}, {});
  }

  async listPodcasts(token: string, pageSize: number, search: string|null, subscribed: boolean|null) {
    return APIService.ENDPOINTS.listPodcasts.run(this.ref, {'nextPageToken': token, 'pageSize': pageSize, search: search??undefined, subscribed:subscribed??undefined}, {});
  }

  async listEpisodes(token: string, pageSize: number, progress: 'ANY'|'PARTIAL'|'NONE'|'FINISHED'|null, search: string|null, liked: boolean|null, sort:'NUMBER'|'LIKES'|'LAST_WATCHED'|null) {
    return APIService.ENDPOINTS.listEpisodes.run(this.ref,{'nextPageToken': token, 'pageSize': pageSize, 'search': search??undefined, 'progress':progress??undefined, 'liked':liked??undefined, 'sort':sort??undefined}, {});
  }

  async getPodcast(podcastid: string) {
    return APIService.ENDPOINTS.getPodcast.run(this.ref, {':podid': podcastid}, {});
  }

  async getPodcastEpisodes(podcastid: string) {
    return APIService.ENDPOINTS.getPodcastEpisodes.run(this.ref, {':podid': podcastid}, {});
  }

  async search(searchstr: string, token: string, pageSize: number) {
    return APIService.ENDPOINTS.search.run(this.ref, {'nextPageToken': token, 'pageSize': pageSize, 'search': searchstr}, {'':searchstr});
  }
}
