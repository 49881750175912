import {Component, ViewContainerRef} from '@angular/core';
import {EpisodeWithMeta} from "../../apimodels/episode";
import {APIService} from "../../services/api.service";
import {AuthenticationService} from "../../services/authentication.service";
import {ActivatedRoute, Router} from "@angular/router";
import {AudioplayerEventType, AudioplayerService} from "../../services/audioplayer.service";

@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.scss']
})
export class MainComponent {
  searchtext: string = '';

  constructor(private api: APIService,
              private auth: AuthenticationService,
              private router: Router,
              private activatedRoute: ActivatedRoute)
  {
    //
  }

  async search(searchtext: string) {
    await this.router.navigate(['search'], {queryParams: {'q': searchtext}})
  }

  isSearchPage() {
    return this.router.url.startsWith('/search');
  }

  async closeSearch() {
    await this.router.navigate(['/']);
  }
}
