
<div id="primary">

  <aside>

    <img class="sb-logo" src="assets/logo.png" alt="logo">

    <a class="sb-btn" routerLink="/">Home</a>
    <a class="sb-btn" routerLink="/explore">Entdecken</a>
    <a class="sb-btn" routerLink="/subscriptions">Abos</a>
    <a class="sb-btn" routerLink="/history">Verlauf</a>
    <a class="sb-btn" routerLink="/admin">Administration</a>

    <div class="sb-stretch"></div>

    <div class="sb-footer">

      <img class="avatar" src="assets/avatar.png" alt="avatar">

      <div class="username">Hans-Uwe</div>
      <div class="account-type">#Administrator</div>

      <div class="settings">
        <app-icon-btn size="24px" icon="cog" (btnClick)="toast.error('Hello there')"></app-icon-btn>
      </div>

      <div class="logout">
        <app-icon-btn size="24px" icon="right-from-bracket" (btnClick)="logout()"></app-icon-btn>
      </div>

    </div>

  </aside>

  <main><ng-content></ng-content></main>

</div>


