<div *ngIf="podcast === null" class="loading-root">
  <fa-icon icon="spinner" [spin]="true"></fa-icon>
</div>

<div *ngIf="podcast !== null" class="root">

  <img class="cover" [src]="podcast.mainImage | imageurl" alt="Cover">

  <span class="title">{{podcast.title}}</span>

  <span class="date">{{podcast.timestampCreated | date: 'yyyy-MM-dd HH:mm:ss'}}</span>

  <div class="description">{{podcast.description}}</div>

  <div class="divider"></div>

  <div class="entrylist">


    <span *ngIf="episodesLoading" class="entrylist-loader">
      <fa-icon icon="spinner" [spin]="true"></fa-icon>
    </span>


    <ng-container *ngFor="let episode of episodes">

      <app-button-episode [episode]="episode" [singleImage]="true" (click)="onClickEpisode(episode)"></app-button-episode>

    </ng-container>
  </div>

</div>
