import {Component, Input} from '@angular/core';
import {environment} from "../../../environments/environment";
import {APIService} from "../../services/api.service";
import {AuthenticationService} from "../../services/authentication.service";
import {ActivatedRoute, Router} from "@angular/router";
import {EpisodeWithMeta} from "../../apimodels/episode";
import {PodcastWithMeta} from "../../apimodels/podcast";

@Component({
  selector: 'app-button-podcast',
  templateUrl: './button-podcast.component.html',
  styleUrls: ['./button-podcast.component.scss']
})
export class ButtonPodcastComponent {
  @Input() podcast: PodcastWithMeta|null = null;

  constructor(private api: APIService,
              private auth: AuthenticationService,
              private router: Router,
              private activatedRoute: ActivatedRoute)
  {
    //
  }

}
