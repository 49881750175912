export function nrange(start: number, end: number): number[] {
  let ans = [];
  for (let i = start; i < end; i++) ans.push(i);
  return ans;
}

export function unbracket(v: string, prefix: string, suffix: string): string {
  if (v.startsWith(prefix) && v.endsWith(suffix) && v.length >= (prefix.length + suffix.length)) {
    return v.substring(prefix.length, v.length - suffix.length);
  } else {
    return v;
  }
}

export function base64DecodeUnicode(str: string): string {
  // Convert Base64 encoded bytes to percent-encoding, and then get the original string.
  const percentEncodedStr = atob(str).split('').map(c => '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)).join('');

  return decodeURIComponent(percentEncodedStr);
}

export function parseBool(value: any, defaultValue: boolean = false): boolean {
  const v = value.toString().toLowerCase();

  if (['true', '1', 'y', 'yes'].includes(v)) return true;
  if (['false', '0', 'n', 'no'].includes(v)) return false;

  return defaultValue;
}
