import {RFC3339} from "../models/datetime";
import {Session, SessionWithMeta} from "./session";

export type UserPemissions = "GUEST"|"USER"|"ADMIN";

export interface User {
  userId: string;
  username: string;
  mail: string|null;
  mailVerified: boolean;
  permissions: UserPemissions;
  companyId: string|null;
  timestampCreated: RFC3339;
  timestampLastLogin: RFC3339;
  timestampLastUpdated: RFC3339;
}

export function guardUser(v: object): v is User {
  if ((v as User).userId === undefined) return false;
  if ((v as User).username === undefined) return false;
  if ((v as User).mail === undefined) return false;
  if ((v as User).mailVerified === undefined) return false;
  if ((v as User).permissions === undefined) return false;
  if ((v as User).companyId === undefined) return false;
  if ((v as User).timestampCreated === undefined) return false;
  if ((v as User).timestampLastLogin === undefined) return false;
  if ((v as User).timestampLastUpdated === undefined) return false;
  return true;
}
