<app-login-frame>
    <div class="card">

        <span class="header">Promentis - Registrierung</span>

        <img class="logo" src="assets/logo.png" alt="logo">

        <input                 class="username"  [(ngModel)]="mail"                     placeholder="Mail"      (keyup.enter)="this.inputUsername.focus()" >
        <input  #inputUsername class="username"  [(ngModel)]="username"                 placeholder="Username"  (keyup.enter)="this.inputPassword.focus()" >
        <input  #inputPassword class="password"  [(ngModel)]="password" type="password" placeholder="Passwort"  (keyup.enter)="register()" >

        <button class="login {{loading ? 'loading' : ''}}" (click)="register()"><fa-icon icon="spinner" [spin]="true"></fa-icon>Registrieren</button>

        <div class="error-display" *ngIf="errorText !== ''">{{errorText}}</div>

    </div>
</app-login-frame>
