<div class="root">

  <span class="headersep">Weiter Hören</span>

  <div class="entrylist">

    <span *ngIf="listeningLoading" class="entrylist-loader">
      <fa-icon icon="spinner" [spin]="true"></fa-icon>
    </span>

    <ng-container *ngIf="!listeningLoading">
      <ng-container *ngFor="let episode of listeningEpisodes">

        <app-button-episode [episode]="episode" [singleImage]="false" (click)="onClickEpisode(episode)"></app-button-episode>

      </ng-container>
    </ng-container>

  </div>

  <span class="headersep">Entdecken</span>

  <div class="entrylist">

    <span *ngIf="exploreLoading" class="entrylist-loader">
      <fa-icon icon="spinner" [spin]="true"></fa-icon>
    </span>

    <ng-container *ngIf="!exploreLoading">
      <ng-container *ngFor="let podcast of explorePodcasts">

        <app-button-podcast [podcast]="podcast" (click)="onClickPodcast(podcast)"></app-button-podcast>

      </ng-container>
    </ng-container>

  </div>

</div>
