
<default-frame>

  <div id="root">
    <div class="search"><app-searchbar [(searchtext)]="searchtext" (onSearch)="search(searchtext)" (onClose)="closeSearch()" [showClose]="searchtext !== '' && isSearchPage()"></app-searchbar></div>

    <div class="maincontent"><router-outlet></router-outlet></div>

    <app-bottom-player id="player"></app-bottom-player>

    <div class="footer">@promentis 2022</div>
  </div>

</default-frame>


