import {parseBool, unbracket} from './lang';
import {DateUtils} from './date';

type SParamType = number | Date | string | number[] | string[] | boolean | null | undefined;
type SParamValues = Array<{ text: string, value: any }>;

export interface SerializedParam {
  active: SParamType;
  default: SParamType;
  type: 'string'|'number'|'number[]'|'string[]'|'date'|'boolean'|'custom';
  values?: SParamValues;
  serialize?: ((v: SParamType) => string);
  deserialize?: ((v: string) => [SParamType, SParamValues]);
  serializeForAPI?: ((v: SParamType) => string);
  api?: boolean;
  apiKey?: string;
}

export interface SerializedParamCollection {
  [p: string]: SerializedParam;
}

export class SerializedParamUtil {

  public static ParameterToPath(base: string, coll: SerializedParamCollection): string {
    let args = [];

    for (const key of Object.keys(coll)) {
      const val = coll[key].active;
      if (coll[key].default == null && !this.IsActive(coll[key], val)) continue;
      if (coll[key].active === coll[key].default) continue;

      if (coll[key].serialize) {
        args.push(`${key}=${coll[key].serialize!(val)}`);
      } else {
        switch (coll[key].type) {
          case 'date':
            if (val === null) args.push(`${key}=($null$)`);
            else args.push(`${key}=${(val as Date).toISOString()}`);
            break;
          case 'string':
            if (val === null) args.push(`${key}=($null$)`);
            else args.push(`${key}=` + encodeURIComponent(`${val}`));
            break;
          case 'number':
            if (val === null) args.push(`${key}=($null$)`);
            else args.push(`${key}=${val}`);
            break;
          case 'number[]':
            if (val === null) args.push(`${key}=[]`);
            else args.push(`${key}=[${(val as number[]).join(',')}]`);
            break;
          case 'string[]':
            if (val === null) args.push(`${key}=[]`);
            else args.push(`${key}=[${(val as string[]).join(',')}]`);
            break;
          case 'boolean':
            if (val === null) args.push(`${key}=($null$)`);
            else args.push(`${key}=${(val as boolean) ? 'true' : 'false'}`);
            break;
        }
      }
    }

    if (args.length === 0) return base;

    const path = base + "?" + args.join('&');

    return path;
  }

  public static ParameterFromPath(urlparams: any, old: SerializedParamCollection): SerializedParamCollection {

    let coll = old;

    for (const key of Object.keys(coll)) {
      coll[key].active = coll[key].default;
    }

    if (urlparams === null || urlparams === undefined) return coll;

    for (const key of Object.keys(coll)) {
      const paramvalue = urlparams[key];
      if (!this.IsActive(coll[key], paramvalue)) continue;

      if (coll[key].deserialize) {
        [coll[key].active, coll[key].values] = coll[key].deserialize!(String(paramvalue));
      } else {
        switch (coll[key].type) {
          case 'string':
            if (`${paramvalue}` === '($null$)') coll[key].active = null;
            else coll[key].active = `${paramvalue}`;
            break;
          case 'number':
            if (`${paramvalue}` === '($null$)') coll[key].active = null;
            else coll[key].active = Number(paramvalue);
            break;
          case 'date':
            if (`${paramvalue}` === '(..null..)') coll[key].active = null;
            else if (`${paramvalue}` === '$today$') coll[key].active = DateUtils.today();
            else if (`${paramvalue}` === '$now$') coll[key].active = DateUtils.now();
            else if (`${paramvalue}` === '$tomorrow$') coll[key].active = DateUtils.tomorrow();
            else if (`${paramvalue}` === '$yesterday$') coll[key].active = DateUtils.yesterday();
            else coll[key].active = new Date(paramvalue);
            break;
          case 'number[]':
            coll[key].active = unbracket(`${paramvalue}`, '[', ']').split(',').map(p => Number(p));
            if ((coll[key].active as any[]).length === 0) coll[key].active = null;
            break;
          case 'string[]':
            coll[key].active = unbracket(`${paramvalue}`, '[', ']').split(',').map(p => String(p));
            if ((coll[key].active as any[]).length === 0) coll[key].active = null;
            break;
          case 'boolean':
            if (`${paramvalue}` === '($null$)') coll[key].active = null;
            else coll[key].active = parseBool(paramvalue);
            break;
        }
      }
    }

    return coll;
  }

  private static IsActive(param: SerializedParam, val: SParamType): boolean {
    if (val === null) return false;
    if (val === undefined) return false;
    if ((val instanceof Array) && (val as any[]).length === 0) return false;
    if (('number' === typeof val) && isNaN(val as number)) return false;

    return true;
  }

  public static ToAPIParams(coll: SerializedParamCollection|null): {[_:string]:string} {
    if (!coll) return {};

    let result: {[_:string]:string} = {};

    const keys = Object.keys(coll);
    for (const key of keys) {
      const val = coll[key].active;
      if (!this.IsActive(coll[key], val)) continue;
      if (coll[key].api !== undefined && !coll[key].api) continue;

      let apikey: string = key;
      if (coll[key].apiKey !== undefined) apikey = coll[key].apiKey!;

      if (coll[key].serializeForAPI) {
        result[apikey] = coll[key].serializeForAPI!(val);
      } else {
        switch (coll[key].type) {
          case 'string':
            result[apikey] = `${val}`;
            break;
          case 'number':
            result[apikey] = `${val}`;
            break;
          case 'date':
            result[apikey] = (val as Date).toISOString();
            break;
          case 'number[]':
            result[apikey] = `${(val as number[]).join(',')}`;
            break;
          case 'string[]':
            result[apikey] = `${(val as string[]).join(',')}`;
            break;
          case 'boolean':
            result[apikey] = ((val as boolean) ? 'true' : 'false');
            break;
        }
      }
    }
    return result;
  }

  public static ToAPIURL(base: string, coll: SerializedParamCollection|null): string {
    let url = base;

    const data = this.ToAPIParams(coll);

    for (const key of Object.keys(data)) {
      url += '&' + key + '=' + encodeURIComponent(data[key]);
    }

    return url;
  }
}
