import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoginComponent } from './pages/login/login.component';
import { HomeComponent } from './pages/home/home.component';
import { ExploreComponent } from './pages/explore/explore.component';
import { AccountComponent } from './pages/account/account.component';
import { DefaultFrameComponent } from './frames/default-frame/default-frame.component';
import { SubscriptionListComponent } from './pages/subscription-list/subscription-list.component';
import { SearchbarComponent } from './components/searchbar/searchbar.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { BottomPlayerComponent } from './components/bottom-player/bottom-player.component';
import { ButtonEpisodeComponent } from './components/button-episode/button-episode.component';
import { ButtonPodcastComponent } from './components/button-podcast/button-podcast.component';
import { LoginFrameComponent } from './frames/login-frame/login-frame.component';
import {FormsModule} from "@angular/forms";
import {HTTP_INTERCEPTORS, HttpClientModule} from "@angular/common/http";
import {IconBtnComponent} from "./components/icon-btn/icon-btn.component";
import { CheckboxComponent } from './components/checkbox/checkbox.component';
import { MainComponent } from './pages/main/main.component';
import {ImageURLPipe} from "./pipes/image-url.pipe";
import { PodcastViewComponent } from './pages/podcast-view/podcast-view.component';
import { SearchComponent } from './pages/search/search.component';
import { HistoryComponent } from './pages/history/history.component';
import { AdminComponent } from './pages/admin/admin.component';
import { HotToastModule } from '@ngneat/hot-toast';
import { RegisterComponent } from './pages/register/register.component';

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    HomeComponent,
    ExploreComponent,
    AccountComponent,
    DefaultFrameComponent,
    SubscriptionListComponent,
    SearchbarComponent,
    BottomPlayerComponent,
    ButtonEpisodeComponent,
    ButtonPodcastComponent,
    LoginFrameComponent,
    IconBtnComponent,
    CheckboxComponent,
    MainComponent,
    ImageURLPipe,
    PodcastViewComponent,
    SearchComponent,
    HistoryComponent,
    AdminComponent,
    RegisterComponent,
  ],
    imports: [
        BrowserModule,
        AppRoutingModule,
        FontAwesomeModule,
        FormsModule,
        HttpClientModule,
        HotToastModule.forRoot({icon: '', position: 'top-right', style: {'font-size': '2em'} }),
    ],
  providers: [],
  bootstrap: [AppComponent]

})
export class AppModule { }
