import { Component } from '@angular/core';
import {APIService} from "../../services/api.service";
import {AuthenticationService} from "../../services/authentication.service";
import {ActivatedRoute, Router} from "@angular/router";
import {HotToastService} from "@ngneat/hot-toast";

@Component({
  selector: 'default-frame',
  templateUrl: './default-frame.component.html',
  styleUrls: ['./default-frame.component.scss']
})
export class DefaultFrameComponent {

  constructor(private api: APIService,
              private auth: AuthenticationService,
              public  toast: HotToastService,
              private router: Router)
  {
  }

  async logout() {
    this.auth.clearAuth();
    await this.router.navigate(['login']);
  }
}
