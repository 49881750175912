import { Component } from '@angular/core';
import {APIService} from '../../services/api.service';
import {AuthenticationService} from '../../services/authentication.service';
import {Router} from '@angular/router';
import {isErrorCode} from '../../utils/api';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss']
})
export class RegisterComponent {
  mail: string = '';
  password: string = '';
  username: string = '';
  loading: boolean = false;
  errorText: string = '';

  constructor(private api: APIService, private auth: AuthenticationService, private router: Router) {}

  async register() {
    try {
      const sess = await this.api.register(navigator.userAgent, this.mail, this.username, this.password);
      this.auth.setAuth(sess);
      await this.router.navigate(['/']);
    } catch (err) {
      console.error(err);
      if (isErrorCode(err, 'MAIL_ALREADY_EXISTS')) {
        this.errorText = 'Es ist bereits ein Account mit dieser Mail vorhanden. Bitte logge dich ein.';
      } else if (isErrorCode(err, 'USERNAME_ALREADY_EXISTS')) {
        this.errorText = 'Es ist bereits ein Account mit diesem Usernamen vorhanden. Bitte ändere den Usernamen und versuche es erneut.';
      } else if (isErrorCode(err, 'INTERNAL_ERROR')) {
        this.errorText = 'Es ist ein Fehler aufgetreten. Bitte später erneut versuchen';
      } else if (isErrorCode(err, 'PASSWORD_PRECONDITION_FAILED')) {
        this.errorText = 'Das Passwort muss mindestens 8 Zeichen haben. Bitte ändere das Passwort und versuche es erneut.'
      }
    }
  }

}
