import { Pipe, PipeTransform } from '@angular/core';
import {APIService} from "../services/api.service";
import {AuthenticationService} from "../services/authentication.service";
import {ActivatedRoute, Router} from "@angular/router";
import {environment} from "../../environments/environment";

@Pipe({ name: 'imageurl' })
export class ImageURLPipe implements PipeTransform {

  constructor(private api: APIService,
              private auth: AuthenticationService,
              private router: Router,
              private activatedRoute: ActivatedRoute)
  {
  }

  transform(iid: string): string {
    return environment.apiBaseUrl + '/images/' + iid + '?xauth_bearer=' + (this.auth.getSession()?.secret ?? '');
  }
}
