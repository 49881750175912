import {Component, EventEmitter, Input, Output} from '@angular/core';

@Component({
  selector: 'app-searchbar',
  templateUrl: './searchbar.component.html',
  styleUrls: ['./searchbar.component.scss']
})
export class SearchbarComponent {
  @Input() showClose: boolean = false;

  @Input() searchtext: string = '';
  @Output() searchtextChange: EventEmitter<string> = new EventEmitter<string>();

  @Output() onSearch: EventEmitter<string> = new EventEmitter<string>();
  @Output() onClose:  EventEmitter<void>   = new EventEmitter<void>();
}
