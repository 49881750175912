import { Injectable } from '@angular/core';
import {EpisodeWithMeta} from "../apimodels/episode";
import {Subject} from "rxjs";
import {environment} from "../../environments/environment";
import {AuthenticationService} from "./authentication.service";

export type AudioplayerEventType = "EPISODE_CHANGED"|"PLAYBACK_START"|"PLAYBACK_PAUSE"|"PROGRESS_CHANGED";

@Injectable({
  providedIn: 'root'
})
export class AudioplayerService {

  private audioObj = new Audio();

  private currentEpisode: EpisodeWithMeta|null = null;

  private eventSource = new Subject<AudioplayerEventType>();

  audioplayerEvent$ = this.eventSource.asObservable();

  constructor(private auth: AuthenticationService) {
    this.audioObj.addEventListener('durationchange', (e) => { this.onAudioObjDurationChanged(e); })
    this.audioObj.addEventListener('loadeddata', (e) => { this.onAudioObjLoadData(e); })
    this.audioObj.addEventListener('loadedmetadata', (e) => { this.onAudioObjLoadMetadata(e); })
    this.audioObj.addEventListener('play', (e) => { this.onAudioObjPlay(e); })
    this.audioObj.addEventListener('playing', (e) => { this.onAudioObjPlaying(e); })
    this.audioObj.addEventListener('progress', (e) => { this.onAudioObjProgress(e); })
    this.audioObj.addEventListener('timeupdate', (e) => { this.onAudioObjTimeUpdate(e); })
    this.audioObj.addEventListener('seeked', (e) => { this.onAudioObjSeeked(e); })
    this.audioObj.addEventListener('seeking', (e) => { this.onAudioObjSeeking(e); })
    this.audioObj.addEventListener('volumechange', (e) => { this.onAudioObjVolumeChange(e); })
  }

  setCurrentEpisode(episode: EpisodeWithMeta, immediateStartPlaying: boolean) {

    this.currentEpisode = episode;
    this.triggerEvent('EPISODE_CHANGED');

    if (immediateStartPlaying) {

      this.continuePlaying();

    } else {

      this.pausePlaying();

    }

  }

  getCurrentEpisode(): EpisodeWithMeta|null {
    return this.currentEpisode;
  }

  private triggerEvent(apet: AudioplayerEventType) {
    this.eventSource.next(apet);
  }

  isPlaying() {
    return !this.audioObj.ended && !this.audioObj.paused;
  }

  pausePlaying() {
    if (this.isPlaying()) {
      this.audioObj.pause();
      this.triggerEvent('PLAYBACK_PAUSE');
    }
  }

  continuePlaying() {
    if (this.currentEpisode === null) {
      // safety first
      this.pausePlaying();
      return;
    }

    const mediasrc = environment.apiBaseUrl + '/audio/' + this.currentEpisode.mainAudio + '?xauth_bearer=' + (this.auth.getSession()?.secret ?? '');

    if (this.currentEpisode !== null) {

      if (this.audioObj.src === mediasrc) {

        if (this.isPlaying()) return; // already playing correct

        //this.audioObj.load()
        this.audioObj.play().then(() => {});

      } else {

        if (this.isPlaying()) {
          this.pausePlaying();
        }

        this.audioObj.src = mediasrc;
        this.audioObj.load();
        this.audioObj.play().then(() => {});

      }
    }
  }

  private onAudioObjDurationChanged(e: Event) {

  }

  private onAudioObjLoadData(e: Event) {

  }

  private onAudioObjLoadMetadata(e: Event) {

  }

  private onAudioObjPlay(e: Event) {
    this.triggerEvent('PLAYBACK_START');
  }

  private onAudioObjPlaying(e: Event) {

  }

  private onAudioObjProgress(e: ProgressEvent) {

  }

  private onAudioObjSeeked(e: Event) {

  }

  private onAudioObjSeeking(e: Event) {

  }

  private onAudioObjVolumeChange(e: Event) {

  }

  private onAudioObjTimeUpdate(e: Event) {
    this.triggerEvent('PROGRESS_CHANGED');
  }

  getProgress() {
    if (this.currentEpisode === null) return 0;
    return this.audioObj.currentTime / this.audioObj.duration;
  }
}
