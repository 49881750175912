import {RFC3339} from "../models/datetime";
import {guardUser, User} from "./user";

export interface Session {
  sessionId: string;
  secret: string;
  userId: string;
  timestampCreated: RFC3339;
  timestampExpiration: RFC3339;
  deviceName: string;
}

export function guardSession(v: object): v is Session {
  if ((v as SessionWithMeta).sessionId === undefined) return false;
  if ((v as SessionWithMeta).secret === undefined) return false;
  if ((v as SessionWithMeta).userId === undefined) return false;
  if ((v as SessionWithMeta).timestampCreated === undefined) return false;
  if ((v as SessionWithMeta).timestampExpiration === undefined) return false;
  if ((v as SessionWithMeta).deviceName === undefined) return false;
  if ((v as SessionWithMeta).sessionId === undefined) return false;
  return true;
}

export interface SessionWithMeta extends Session{
  sessionId: string;
  secret: string;
  userId: string;
  timestampCreated: RFC3339;
  timestampExpiration: RFC3339;
  deviceName: string;

  user: User;
}

export function guardSessionWithMeta(v: object): v is SessionWithMeta {
  if (!guardSession(v)) return false;
  if ((v as SessionWithMeta).user === undefined) return false;
  if (!guardUser((v as SessionWithMeta).user)) return false;
  return true;
}
