import { Component } from '@angular/core';
import {EpisodeWithMeta} from "../../apimodels/episode";
import {APIService} from "../../services/api.service";
import {AuthenticationService} from "../../services/authentication.service";
import {ActivatedRoute, Router} from "@angular/router";
import {AudioplayerService} from "../../services/audioplayer.service";
import {SearchResult} from "../../apimodels/search";
import {PodcastWithMeta} from "../../apimodels/podcast";
import {HotToastService} from "@ngneat/hot-toast";

@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss']
})
export class SearchComponent {

  loading = true;
  entities: SearchResult[] = [];

  constructor(private api: APIService,
              private auth: AuthenticationService,
              private router: Router,
              private audio: AudioplayerService,
              public  toast: HotToastService,
              private activatedRoute: ActivatedRoute)
  {
    this.activatedRoute.queryParams.subscribe(async params => {
      await this.load(params['q'] ?? '');
    });
  }

  private async load(searchstr: string) {
    this.loading = true;

    try {
      const data = await this.api.search(searchstr, '', 32);

      this.entities = data.entities;

    } catch (err: any) {
      this.toast.error('Interner Fehler'); //TODO look at error ids
    } finally {
      this.loading = false;
    }
  }

  onClickEpisode(episode: EpisodeWithMeta) {
    this.audio.setCurrentEpisode(episode, true);
  }

  onClickPodcast(podcast: PodcastWithMeta) {
    this.router.navigate(['podcast-view', podcast.podcastId]).then(() => {});
  }
}
