<app-login-frame>

  <div class="card">

    <span class="header">Promentis - Login</span>

    <img class="logo" src="assets/logo.png" alt="logo">

    <input                 class="username"  [(ngModel)]="identifier"                  placeholder="Username oder E-Mail" (keyup.enter)="this.inputPassword.focus()" >
    <input  #inputPassword class="password"  [(ngModel)]="password"    type="password" placeholder="Passwort"             (keyup.enter)="login()" >

    <app-checkbox [checked]="ephemeral">Eingeloggt bleiben</app-checkbox>

    <button class="login {{loginLoading ? 'loading' : ''}}" (click)="login()"><fa-icon icon="spinner" [spin]="true"></fa-icon>Login</button>

    <p>Noch kein Account? <a href="/register">Hier Registrieren!</a></p>

    <div class="error-display" *ngIf="errorText != ''">{{errorText}}</div>

  </div>

</app-login-frame>
