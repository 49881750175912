import {Component, ViewContainerRef} from '@angular/core';
import {APIService} from "../../services/api.service";
import {AuthenticationService} from "../../services/authentication.service";
import {ActivatedRoute, Router} from "@angular/router";
import {sleep} from "../../utils/angular";
import {isErrorCode} from "../../utils/api";
import {PodcastWithMeta} from "../../apimodels/podcast";
import {EpisodeWithMeta} from "../../apimodels/episode";
import {MainComponent} from "../main/main.component";
import {AudioplayerService} from "../../services/audioplayer.service";
import {HotToastService} from "@ngneat/hot-toast";

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent {
  listeningLoading: boolean = true;
  exploreLoading: boolean = true;

  explorePodcasts:   PodcastWithMeta[] = [];
  listeningEpisodes: EpisodeWithMeta[] = [];

  constructor(private api: APIService,
              private auth: AuthenticationService,
              private router: Router,
              public  toast: HotToastService,
              private audio: AudioplayerService,
              private activatedRoute: ActivatedRoute)
  {
    this.loadExplorePodcasts().then(() => {});
    this.loadListeningEpisodes().then(() => {});
  }

  async loadListeningEpisodes() {
    this.listeningLoading = true;

    try {
      const data = await this.api.listEpisodes("", 32, "ANY", null, null, "LAST_WATCHED");

      this.listeningEpisodes = data.episodes;

    } catch (err: any) {
      this.toast.error('Konnte Episoden nicht laden'); //TODO look at error ids
    } finally {
      this.listeningLoading = false;
    }
  }

  async loadExplorePodcasts() {
    this.exploreLoading = true;

    try {
      const data = await this.api.listPodcasts("", 32, null, null);

      this.explorePodcasts = data.podcasts;

    } catch (err: any) {
      this.toast.error('Konnte Podcasts nicht laden'); //TODO look at error ids
    } finally {
      this.exploreLoading = false;
    }
  }

  onClickEpisode(episode: EpisodeWithMeta) {
    this.audio.setCurrentEpisode(episode, true);
  }

  onClickPodcast(podcast: PodcastWithMeta) {
    this.router.navigate(['podcast-view', podcast.podcastId]).then(() => {});
  }
}
