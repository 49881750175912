import {HttpErrorResponse} from '@angular/common/http';
import {isAPIError} from "../models/api";


export function formatAPIError(err: any): string {
  console.error('[[API-ERROR]]', err);

  if (!(err instanceof HttpErrorResponse)) {
    return "API Request fehlgeschlagen";
  }

  const apierr = err.error;

  if (!isAPIError(apierr)) {
    return err.message;
  }

  return apierr.message;
}

export function isErrorCode(err: any, errcode: string): boolean {

  if (err.error === undefined) return false;

  if (!isAPIError(err.error)) {
    return false;
  }

  return err.error.errorcode === errcode;
}
