import {Component, EventEmitter, Input, Output} from '@angular/core';
import {IconProp} from "@fortawesome/fontawesome-svg-core";

@Component({
  selector: 'app-icon-btn',
  templateUrl: './icon-btn.component.html',
  styleUrls: ['./icon-btn.component.scss']
})
export class IconBtnComponent {

  @Input() size: string = "1em";
  @Input() icon: IconProp = 'java';
  @Input() disabled: boolean = false;

  @Output() btnClick = new EventEmitter();

}
