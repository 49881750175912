import {Component, Input} from '@angular/core';
import {EpisodeWithMeta} from "../../apimodels/episode";
import {APIService} from "../../services/api.service";
import {AuthenticationService} from "../../services/authentication.service";
import {Router} from "@angular/router";
import {AudioplayerEventType, AudioplayerService} from "../../services/audioplayer.service";

@Component({
  selector: 'app-bottom-player',
  templateUrl: './bottom-player.component.html',
  styleUrls: ['./bottom-player.component.scss']
})
export class BottomPlayerComponent {
  activeEpisode: EpisodeWithMeta|null = null;
  progressPerc = 0;
  isPlaying = false;

  constructor(private api: APIService,
              private auth: AuthenticationService,
              private router: Router,
              private audio: AudioplayerService)
  {
    audio.audioplayerEvent$.subscribe((e) => { this.onAudioPlayerEvent(e); });
  }

  private onAudioPlayerEvent(evtType: AudioplayerEventType) {
    switch (evtType) {
      case "EPISODE_CHANGED":
        this.activeEpisode = this.audio.getCurrentEpisode();
        break;
      case "PROGRESS_CHANGED":
        this.progressPerc = this.audio.getProgress() * 100;
        break;
      case "PLAYBACK_PAUSE":
      case "PLAYBACK_START":
        this.isPlaying = this.audio.isPlaying();
        break;
    }
  }

  play() {
    this.audio.continuePlaying();
  }

  pause() {
    this.audio.pausePlaying();
  }
}
