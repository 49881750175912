import {HttpClient, HttpErrorResponse} from '@angular/common/http';
import {catchError, lastValueFrom, map, throwError} from 'rxjs';
import {SerializedParamCollection, SerializedParamUtil} from "./serializedParameter";
import {AuthenticationService} from "../services/authentication.service";
import {environment} from "../../environments/environment";
import {APIService} from "../services/api.service";
import {Router} from "@angular/router";
import {sleep} from "./angular";
import {HotToastService} from "@ngneat/hot-toast";

export enum APIRequestMethod {'GET', 'POST', 'PUT', 'DELETE', 'PATCH'}

interface Parameter {
  [_:string]: string|number|boolean|undefined;
}

export interface APIServiceRef {
  http: HttpClient;
  auth: AuthenticationService
  router: Router
  toast: HotToastService
  api: APIService
}

export class APIMethod<T> {

  method: APIRequestMethod;
  uri: string;

  constructor(m: APIRequestMethod, u: string) {
    this.method = m;
    this.uri = u;
  }

  private rstr(): string {
    switch (this.method) {
      case APIRequestMethod.DELETE: return 'DELETE';
      case APIRequestMethod.GET:    return 'GET';
      case APIRequestMethod.POST:   return 'POST';
      case APIRequestMethod.PUT:    return 'PUT';
      case APIRequestMethod.PATCH:  return 'PATCH';
    }
  }

  run(ref: APIServiceRef, params: Parameter, body: any|null, filter?: SerializedParamCollection): Promise<T> {
    let urlbase = this.uri;
    let urlext = '';

    let hasParams = false;
    if (params !== null) {
      for (const key of Object.keys(params)) {
        if (params[key] === undefined) continue
        if (key.startsWith(':') && urlbase.includes('{'+key.substring(1)+'}')) {
          urlbase = urlbase.replace('{'+key.substring(1)+'}', `${params[key]}`);
          continue;
        }

        urlext += hasParams ? '&' : '?';
        urlext += key + '=' + encodeURIComponent(params[key]!);
        hasParams = true;
      }
    }
    if (filter !== undefined) {
      const fp = SerializedParamUtil.ToAPIParams(filter);
      for (const key of Object.keys(fp)) {
        urlext += hasParams ? '&' : '?';
        urlext += key + '=' + encodeURIComponent(fp[key]);
        hasParams = true;
      }
    }

    const url = environment.apiBaseUrl+"/"+urlbase+urlext;

    const prom = ref.http.request<T>(this.rstr(), url, {body: body, observe: 'response', headers: {"Authorization": "Bearer " +  ref.auth.getSecret()}}).
    pipe(catchError((error: HttpErrorResponse) => {

      if (error.status === 401) {
        console.log('HTTP returned 401');
        ref.auth.clearAuth();
        sleep(0).then(() => {
          ref.toast.error('Session ungültig - Sie werden ausgeloggt.');
          ref.router.navigate(['/']).then(() => {});
        });
      }

      return throwError(() => error);
    })).
    pipe(map(data => {
      console.log('API', data)
      return data.body!;
    }));

    return lastValueFrom(prom);
  }

}
