import {Component, Input} from '@angular/core';
import {environment} from "../../../environments/environment";
import {APIService} from "../../services/api.service";
import {AuthenticationService} from "../../services/authentication.service";
import {ActivatedRoute, Router} from "@angular/router";
import {EpisodeWithMeta} from "../../apimodels/episode";

@Component({
  selector: 'app-button-episode',
  templateUrl: './button-episode.component.html',
  styleUrls: ['./button-episode.component.scss']
})
export class ButtonEpisodeComponent {
  @Input() episode: EpisodeWithMeta|null = null;
  @Input() singleImage: boolean = false;

  constructor(private api: APIService,
              private auth: AuthenticationService,
              private router: Router,
              private activatedRoute: ActivatedRoute)
  {
    //
  }

}
