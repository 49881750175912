
<div id="search">

  <input type="search"
         placeholder="Search"
         [(ngModel)]="searchtext"
         (change)="searchtextChange.emit(searchtext)"
         (keyup.enter)="onSearch.emit(searchtext)"
         (keyup.escape)="onClose.emit()">

  <fa-icon *ngIf="!showClose" icon="search" (click)="onSearch.emit(searchtext)"></fa-icon>
  <fa-icon *ngIf="showClose"  icon="close"  (click)="onClose.emit()"           ></fa-icon>

</div>
