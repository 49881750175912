import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import {HomeComponent} from "./pages/home/home.component";
import {LoginComponent} from "./pages/login/login.component";
import {AuthGuard} from "./helper/auth.guard";
import {ExploreComponent} from "./pages/explore/explore.component";
import {AccountComponent} from "./pages/account/account.component";
import {SubscriptionListComponent} from "./pages/subscription-list/subscription-list.component";
import {MainComponent} from "./pages/main/main.component";
import {HistoryComponent} from "./pages/history/history.component";
import {AdminComponent} from "./pages/admin/admin.component";
import {SearchComponent} from "./pages/search/search.component";
import {PodcastViewComponent} from "./pages/podcast-view/podcast-view.component";
import {RegisterComponent} from './pages/register/register.component';

const routes: Routes = [
  { path: 'login',         component: LoginComponent,                                      },
  { path: 'register',      component: RegisterComponent,                                   },

  { path: '',              component: MainComponent, canActivate: [AuthGuard], children:
  [
      { path: '',                     component: HomeComponent,             canActivate: [AuthGuard], },
      { path: 'explore',              component: ExploreComponent,          canActivate: [AuthGuard], },
      { path: 'subscriptions',        component: SubscriptionListComponent, canActivate: [AuthGuard], },
      { path: 'account',              component: AccountComponent,          canActivate: [AuthGuard], },
      { path: 'history',              component: HistoryComponent,          canActivate: [AuthGuard], },
      { path: 'admin',                component: AdminComponent,            canActivate: [AuthGuard], },
      { path: 'search',               component: SearchComponent,           canActivate: [AuthGuard], },
      { path: 'podcast-view/:podid',  component: PodcastViewComponent,      canActivate: [AuthGuard], },
  ]},
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
