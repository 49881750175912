import {Component, OnInit} from '@angular/core';
import {APIService} from "../../services/api.service";
import {AuthenticationService} from "../../services/authentication.service";
import {ActivatedRoute, Router} from "@angular/router";
import {AudioplayerService} from "../../services/audioplayer.service";
import {PodcastWithMeta} from "../../apimodels/podcast";
import {EpisodeWithMeta} from "../../apimodels/episode";
import {HotToastService} from "@ngneat/hot-toast";

@Component({
  selector: 'app-podcast-view',
  templateUrl: './podcast-view.component.html',
  styleUrls: ['./podcast-view.component.scss']
})
export class PodcastViewComponent implements OnInit  {

  podcastId: string|null = null;
  podcast: PodcastWithMeta|null = null;
  episodesLoading = true;
  episodes: EpisodeWithMeta[] = [];

  constructor(private api: APIService,
              private auth: AuthenticationService,
              private router: Router,
              public  toast: HotToastService,
              private audio: AudioplayerService,
              private activatedRoute: ActivatedRoute)
  {
    //
  }

  async ngOnInit() {
    this.podcastId = this.activatedRoute.snapshot.params['podid'];
    await this.load();
  }

  private async load() {
    this.podcast = null;
    this.episodesLoading = true;
    try {
      const data = await this.api.getPodcast(this.podcastId!);

      this.podcast = data;

    } catch (err: any) {
      this.toast.error('Konnte Podcast nicht laden'); //TODO look at error ids
      return;
    }

    try {
      const data = await this.api.getPodcastEpisodes(this.podcastId!);

      this.episodes = data.episodes;
      this.episodesLoading = false;

    } catch (err: any) {
      this.toast.error('Konnte Episoden nicht laden'); //TODO look at error ids
      return;
    }
  }

  onClickEpisode(episode: EpisodeWithMeta) {
    this.audio.setCurrentEpisode(episode, true);
  }
}
