
<div *ngIf="episode !== null" class="root">

  <div *ngIf="!singleImage" class="images double">
    <img class="podcast-image" [src]="episode.podcast.mainImage | imageurl" alt="podcast cover">
    <div class="episode-image-border"><img class="episode-image" [src]="episode.coverImage | imageurl" alt="episode cover"></div>
  </div>

  <div *ngIf="singleImage" class="images single">
    <img [src]="episode.coverImage | imageurl" alt="cover">
  </div>

  <span *ngIf="!singleImage"  class="podcast-title"><span>{{episode.podcast.title}}</span></span>
  <span *ngIf="!singleImage"  class="episode-title"><span><span class="number">#{{episode.number}}</span> {{episode.title}}</span></span>

  <span *ngIf="singleImage"   class="single-title"><span><span class="number">#{{episode.number}}</span> {{episode.title}}</span></span>

</div>
