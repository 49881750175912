import {SimpleChanges} from '@angular/core';

export function isNgDiff(changes: SimpleChanges, key: string) {
  return changes[key] !== undefined && JSON.stringify(changes[key].previousValue) !== JSON.stringify(changes[key].currentValue);
}

export function uniqueRandID() {
  return Date.now().toString(26).padStart(10, '0') + "_" +
         Math.random().toString(26).substring(2, 6) + "_" +
         Math.random().toString(26).substring(2, 6) + "_" +
         Math.random().toString(26).substring(2, 6);
}

export function deepclone<T>(v: T): T {
  if (v === null) {
    // @ts-ignore
    return null;
  }
  if (v === undefined) {
    // @ts-ignore
    return undefined;
  }
  if (Array.isArray(v)) {
    return (v as any[]).map(p => deepclone(p)) as unknown as T;
  }
  if (v instanceof Date) {
    return new Date(v.getTime()) as unknown as T;
  }
  if (typeof v === 'object') {
    return Object.getOwnPropertyNames(v).reduce((o, prop) =>
    {
      Object.defineProperty(o, prop, Object.getOwnPropertyDescriptor(v, prop)!);
      o[prop] = deepclone((v as { [key: string]: any })[prop]);
      return o;
    }, Object.create(Object.getPrototypeOf(v)));
  }
  return JSON.parse(JSON.stringify(v));
}

export const sleep = (milliseconds: number) => {
  return new Promise(resolve => setTimeout(resolve, milliseconds));
};
